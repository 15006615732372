<template>
    <div class="contentS">
        <div class="content">
            <tabs swipeable :swipe-threshold="3" :line-width='50'  @change="orderChange"  :sticky="true" v-model="type">
                <tab :title="item.title" v-for="item in tabList"  :key="item.type" :value="item.type" title-class="ft">
                    <div class="list">
                        <div :key="index" @click="goView(item.id)" class="item" v-for="(item,index) in list">
                            <img :src="$url + item.image" alt="">
                            <div class="view">
                                <h1>{{item.title}}</h1>
                                <strong v-html="item.summary_content"></strong>
                                <p>
                                    {{item.createtime | time}}
                                </p>

                            </div>
                        </div>

                        <p @click="fetchMore" class="more" v-if="page < sumPage">查看更多</p>
                        <div class="empty" v-if="empty || list.length == 0">
                            <img alt="" src="../assets/dong/empty.jpg">
                        </div>

                    </div>

                </tab>
            </tabs>

        </div>
    </div>
</template>

<script>

    import moment from "moment";

    import {Tab, Tabs} from 'vant';

    export default {
        name: "eyeList",
        data() {
            return {
                page: 1,
                list: [],
                sumPage: 0,
                empty: false,
                loading: true,
                tabList: [
                  {
                    type: 7,
                    title: 'OK镜科普',
                  },
                    {
                      type: 6,
                      title: '角膜塑形',
                    },
                    {
                      type: 5,
                      title: '护眼知识',
                    },
                    {
                        type: 1,
                        title: '幼儿园近视防控手册',
                    },
                    {
                        type: 2,
                        title: '小学生近视防控手册',
                    },
                    {
                        type: 3,
                        title: '初中生近视防控手册',
                    },
                    {
                        type: 4,
                        title: '高中生近视防控手册',
                    },

                ],
                type : 0

            }
        },
        methods: {
            fetchData() {
                this.loading = true
                this.$get('index/wxnews', {params: {page: this.page,type:this.type}})
                    .then(res => {
                        if (res.data) {
                            res.data.data.map(c => {
                                this.list.push(c)
                            })
                            this.sumPage = res.data.last_page

                        } else {
                            this.empty = true
                        }

                    })
                    .catch(() => {
                        this.empty = true
                    })

            },
            goView(id) {
                console.log(id)
                this.$router.push({
                    path: '/eyeView',
                    query: {
                        id: id
                    }
                })
            },
          tabclick(){

          },
          orderChange(name) {
            this.active = name;
            console.log('测试name',name,'测试active',this.active)
          },
            fetchMore() {
                this.page = this.page + 1
                this.fetchData()
            }
        },
        created() {
            document.title = '护眼科普'
            this.fetchData()
        },
        filters: {
            time(dataStr) {
                if (!dataStr || dataStr == 0) {
                    return '              -'
                } else {
                    return moment(dataStr * 1000).format('YYYY-MM-DD')
                }
            }
        },
        components: {
            Tab,
            Tabs
        },
        watch:{
            type(){
                this.list = []
                this.page = 1
                this.sumPage = 1

                this.fetchData()
            }
        }
    }
</script>

<style lang="less" scoped>
    @import '~vant/lib/index.css';
    .content{
        width: 100%;
        height:100% ;
    }
    .contentS {
        width: 100%;
        min-height: 100vh;
        box-sizing: border-box;
        padding: 2.1333vw;
        background: #f1f1f1;

        .list {
            display: flex;
            flex-direction: column;
            margin-top: 2vw;
            width: 100%;
            min-height: 90vh;
            position: relative;

            .item {
                height: 20.6667vw;
                margin-bottom: 1.3333vw;
                background: #ffffff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 2.6667vw;

                img {
                    width: 21.3333vw;
                    height: 15.3333vw;
                    margin-right: 5.3333vw;
                }

                .view {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    height: 20.6667vw;
                    box-sizing: border-box;
                    padding-top: 3vw;

                    h1 {
                        width: 53.3333vw;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 3.7333vw;
                        font-weight: bold;
                        height: 4.3333vw;
                        margin-bottom: 1.3333vw;
                        line-height: 4.33vw;

                    }

                    p {
                        color: #999999;
                        height: 3.0000vw;
                        font-size: 2.7333vw;
                        margin-bottom: 1.3333vw;
                        text-align: right;
                    }

                    strong {
                        width: 53.3333vw;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 2.7333vw;
                        height: 5.3333vw;
                        line-height: 5.33vw;
                    }
                }
            }

        }

        .empty {
            position: absolute;
            background: #ffffff;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 40.0000vw;
                height: 40.0000vw;
            }

        }
    }

    .more {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 4vw;
        color: #999999;
        margin: 3vw 0;
    }
</style>

